import React, { useState, useEffect } from "react"
import styled from "styled-components"

import { FlexParent } from "components/Layouts/Utils"
import SocialLayout from "./SocialLayout"

export const StyledIgPost = styled.div`
    width: calc(33.33% - 1rem);
    transition: 0.3s ease-out opacity;


    @media (max-width: 768px) {
        width: 100%;
    }

    &:hover {
        opacity: 0.8;
    }

    a {
        position: relative;
        height: 0;
        width: 100%;
    
        display: block;
        padding-bottom: 100%;
    }

    img, video {
        position: absolute;
        top: 0;left:0;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`

const IgPost = ({ id, media_type, media_url, permalink }) => {
    return (
        <StyledIgPost>
            <a href={permalink} target="_blank" rel="noopener noreferrer">
                {media_type !== "VIDEO" && <img src={media_url} alt={id} />}
                {media_type === "VIDEO" && <video autoPlay muted loop playsInline src={media_url} alt={id} />}
            </a>
        </StyledIgPost>
    )
}

const Instagram = ({ title, account }) => {
    const [posts, setPosts] = useState([])

    useEffect(() => {
        const getPosts = async () => {

            const request = await fetch(`https://sky-utils.vercel.app/api/ig`)
            const json = await request.json()

            if (json.items) {
                setPosts(json.items.splice(1, 12))
            }

        }

        getPosts()
    }, [])

    return (
        <SocialLayout
            account={account}
            title={title}
            buttonUrl={`https://www.instagram.com/${account}`}
            buttonTitle={`Follow @${account}`}
            icon="instagram"
        >
            <FlexParent
                style={{
                    gap: "1.3rem"
                }}
                justifyContent="space-between"
            >
                {posts.map(post => <IgPost key={post.id} {...post} />)}
                {/* {TEMPORARY_IG_DATA.graphql.user.edge_owner_to_timeline_media.edges.map((node, i) => <IgPost i={i} key={node.shortcode} {...node.node} />)} */}
            </FlexParent>
        </SocialLayout>
    )
}

export default Instagram
