import React from "react"
import Layout from "components/Blog/Layout"

import Twitter from "./Twitter"
import Instagram from "./Instagram"
import Links from "./Links"

const SocialFeed = ({
    twitter, instagram, youtube
}) => {
    return (
        <Layout>
            <Links />
            <Instagram {...instagram} />
            <Twitter {...twitter} />
            {/* <Youtube {...youtube} /> */}
        </Layout>
    )
}

export default SocialFeed