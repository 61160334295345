const data = {
    twitter: {
        title: "Latest Tweets",
        account: "skyweavergame",
        tweets: [
            { tweetId: "1401584750171721732" },
            { tweetId: "1401207305761329154" }
        ]
    },
    instagram: {
        title: "Instagram Posts",
        account: "skyweavergame"
    },
    youtube: {
        title: "Latest Videos",
        account: "Horizon Blockchain Games"
    }
}

export default data