import React from "react"

import styled from "styled-components"

import { P } from "components/Typography"

import FeaturedHeader from "components/Blog/FeaturedHeader"
import { MainContentMaxWidth } from "components/Layouts/MaxWidth"
import { ButtonLink } from "components/Button";

const LinkContainer = styled.div`
text-align: center;

a{transition:0.3s ease-out opacity}
a:hover {
    opacity: 0.5;
}
`

const SocialLayout = ({
    account,
    buttonTitle,
    buttonUrl,
    title,
    children,
    icon = false
}) => {
    return (
        <section style={{ marginBottom: "10rem" }}>
            <FeaturedHeader>
                {title}
            </FeaturedHeader>
            <LinkContainer>
                <a style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto",
                }}
                    target="_blank" rel="noopener noreferrer"
                    href={buttonUrl}>
                    {icon && <img
                        style={{
                            width: "1.5rem",
                            marginRight: "0.56rem"
                        }}
                        src={`/images/${icon}.png`} alt={icon} />}
                    <P
                        style={{
                            color: "var(--lilac)",
                            lineHeight: "1",
                            marginBottom: 0

                        }}>@{account}</P>
                </a>
            </LinkContainer>


            <MainContentMaxWidth style={{
            }}>
                {children}
            </MainContentMaxWidth>

            <div style={{
                textAlign: 'center'
            }}>
                <ButtonLink
                    bgColor='var(--background)'
                    outlineColor="var(--lilac)"
                    href={buttonUrl}>
                    {buttonTitle}
                </ButtonLink>
            </div>
        </section>
    )
}

export default SocialLayout
