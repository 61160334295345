import React from "react";
import styled from "styled-components";

import { Button as ButtonText } from "./Typography";

export const StyledButton = styled.button`
  display: inline-block;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;

  background-color: ${({ bgColor }) => (bgColor ? bgColor : "#fff")};
  color: ${({ color }) => (color ? color : "#fff")};
  width: ${({ width }) => (width ? width : "auto")};
  border: 1px solid ${({ outlineColor }) => (outlineColor ? outlineColor : "rgba(0,0,0,0)")};

  transition: 0.2s ease-out transform, 0.3s ease-out background-color, 0.3s ease-out border-color;
  outline: none;

  &:hover {
    background-color: var(--dark);
    span {
      color: #fff;
    }
    border: 1px solid var(--dark);
  }

  @media (max-width: 650px) {
  }
`;

const StyledA = styled.a`
  display: inline-block;
  margin-top: 0.5rem;
  // margin-left: 1rem;
  // margin-right: 1rem;
`

export const ButtonLink = ({
  href,
  children, color, width, bgColor, outlineColor, className, style, parentStyle
}) => (
  <StyledA style={{ ...parentStyle }} href={href} target="_blank">
    <StyledButton
      {...style}
      color={color}
      width={width}
      bgColor={bgColor}
      outlineColor={outlineColor}
      className={`${className} button`}
    >
      <ButtonText color={color}>{children}</ButtonText>
    </StyledButton>
  </StyledA>
)

const Button = ({ children, color, width, bgColor, onClick, outlineColor, className, style, type, as = "button" }) => {
  const clickEvt = () => {
    if (!onClick) return;
    onClick();
  };

  return (
    <StyledButton
      as={as}
      type={type}
      {...style}
      className={`${className} button`}
      onClick={clickEvt}
      width={width}
      color={color}
      outlineColor={outlineColor}
      bgColor={bgColor}>
      <ButtonText color={color}>{children}</ButtonText>
    </StyledButton>
  );
};

export default Button