import React from "react"
import styled from "styled-components"

import { TwitterTimelineEmbed } from 'react-twitter-embed';

import { FlexParent } from "components/Layouts/Utils"
import SocialLayout from "./SocialLayout"

const TweetContainer = styled.div`
    padding: 2rem 0;
    max-width: 40rem;
    width: 100%;
`

const Twitter = ({ title, account, tweets }) => {
    return (
        <SocialLayout
            account={account}
            title={title}
            buttonUrl={`https://twitter.com/${account}`}
            buttonTitle={`Follow @${account}`}
            icon="twitter"
        >
            <FlexParent>
                <TweetContainer>
                    <TwitterTimelineEmbed
                        theme="dark"
                        borderColor="#685986"
                        linkColor="#c4adee"
                        noHeader
                        noFooter
                        noScrollbar
                        transparent
                        sourceType="profile"
                        userId="1031954937138307072"
                        options={{ tweetLimit: 3 }}
                    />
                </TweetContainer>
            </FlexParent>
        </SocialLayout>
    )
}

export default Twitter
