
import React from "react";

import "css/main.css";
import "css/fonts.css";

import Seo from "components/SeoHead";
import SocialFeed from "components/Community/SocialFeed"

// title, content, footer
import data from "data/community/social-feed-data"

function SocialFeedPage() {
    return (
        <>
            <Seo />
            <SocialFeed
                youtube={data.youtube}
                twitter={data.twitter} instagram={data.instagram} />
        </>
    )
}

export default SocialFeedPage