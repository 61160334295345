import styled from "styled-components"

export const FlexParent = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: ${({ alignItems }) => alignItems ? alignItems : "center"};
    justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : "center"};
`

export const BackgroundImage = styled.img`
    position: absolute;
    top: 0;left:0;
    height: 102%;
    width: 102%;
    object-fit:cover;
    object-position: center;
    transition: 0.4s ease-out transform;
`

export const Gradient = styled.div`
    position: absolute;
    top: 0;left:0;
    height: 105%;
    width: 100%;
    background: linear-gradient(180deg, rgba(12, 6, 30, 0.82) 0%, #0C061E 100%);
    opacity: ${({ opacity }) => (opacity ? opacity : 1)};
`
