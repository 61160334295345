import React from "react"
import styled from "styled-components";

import FeaturedHeader from "components/Blog/FeaturedHeader"
import Image from "components/Image";
import { MedMaxWidth } from "components/Layouts/MaxWidth";
import { P } from "components/Typography"
import { FlexParent } from "components/Layouts/Utils";

const A = styled.a`

	@media (max-width: 750px) {
		display: block;
		text- align: center;
	}
`

const Link = styled.div`
  margin-right: 1rem;
	margin-left: 1rem;
	margin-bottom: 2rem;
	display: flex; 
	align-items: center;
	transition: 0.3s ease-out opacity;

	&:hover {
		opacity: 0.6;
	}

	p {
		margin-bottom: 0;
	}

	figure {
		width: 1.5rem;
		margin-right: 0.5rem;
	}
	
	img {
		width: 100%;
	}


`;




const links = [
	{
		url: "https://skyweaver.chat",
		label: "discord.gg/skyweaver",
		src: "/images/discord.png",
		alt: "Discord"
	},
	{
		url: "https://twitter.com/SkyweaverGame",
		label: "@SkyweaverGame",
		src: "/images/twitter.png",
		alt: "Twitter"
	},
	{
		url: "https://www.instagram.com/skyweavergame/",
		label: "@SkyweaverGame",
		src: "/images/instagram.png",
		alt: "Instagram"
	},
	{
		url: "https://www.reddit.com/r/Skyweaver/",
		label: "/r/Skyweaver",
		src: "/images/reddit.png",
		alt: "Reddit"
	},
	{
		url: "https://www.facebook.com/SkyweaverOfficial",
		label: "@SkyweaverOfficial",
		src: "/images/facebook.png",
		alt: "Facebook"
	},
	{
		url: "https://www.twitch.tv/skyweaverlive",
		label: "@SkyweaverLive",
		src: "/images/twitch.png",
		alt: "Twitch"
	},

	{
		url: "https://www.youtube.com/channel/UClFG7LMrK6icGVyCpNDJlVQ",
		label: "Horizon Blockchain Games",
		src: "/images/youtube.png",
		alt: "Youtube"
	},

]

const Links = () => {
	return (
		<section style={{ marginBottom: "8rem", marginTop: "4rem" }}>
			<FeaturedHeader>
				Official Channels
			</FeaturedHeader>

			<MedMaxWidth style={{
				marginTop: "4rem",
				paddingBottom: "0rem"
			}}>
				<FlexParent>
					{links.map(link => <A
						href={link.url}
						target="_blank"
						rel="noopener noreferrer"
					>
						<Link className="link">
							<Image src={link.src} alt={link.alt} />
							<P color="var(--lilac)">{link.label}</P>
						</Link>
					</A>
					)}
				</FlexParent>

			</MedMaxWidth>


		</section>
	)
}

export default Links